interface Props {
  href: string;
  text: string;
  color?: string;
}

const Button = ({ href, text, color = "#000" }: Props) => {
  return (
    <a
      href={href}
      className={
        "text-white py-2 px-6 font-medium w-full flex items-center justify-center rounded-full"
      }
      style={{
        backgroundColor: color,
      }}
    >
      {text}
    </a>
  );
};

export default Button;
