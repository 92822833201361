import { SupplierDeal } from "../types";
import useMoney from "../hooks/useMoney";
import { useAppConfig } from "../hooks/useAppConfig";
import useCalculation from "../hooks/useCalculation";
import useSubscriptionFormat from "../hooks/useSubscriptionFormat";

interface Props {
  deal: SupplierDeal;
}

const DealDetail = ({ deal }: Props) => {
  const { kwh } = useAppConfig();
  const { subscriptions, pricePrKwh, extraCharges } = useCalculation();
  const { periodText, extraChargePeriodText } = useSubscriptionFormat();
  const { addVat, inDKK, inDKKOre } = useMoney();

  return (
    <div>
      <h3 className={"text-xl font-medium border-b border-gray-200 mb-2 pb-2"}>
        {deal.provider.name}
      </h3>
      <ul className={"space-y-1 text-sm"}>
        <li className={"flex justify-between"}>
          <span>Produkt</span>
          <span>{deal?.name}</span>
        </li>
        <li className={"flex justify-between"}>
          <span>Spottillæg</span>
          <span>{inDKK(addVat(deal?.supplement_pr_kwh))}</span>
        </li>
        {deal.green_supplement > 0 && (
          <li className={"flex justify-between"}>
            <span>Grønt spottilæg</span>
            <span>{inDKK(addVat(deal?.green_supplement))}</span>
          </li>
        )}
        <li className={"flex justify-between"}>
          <span>Abonnement</span>
          <span>
            {inDKK(addVat(deal?.subscription_price))} /{" "}
            {periodText(deal.subscription_period)}
          </span>
        </li>
        {deal.subscription_green_supplement > 0 && (
          <li className={"flex justify-between"}>
            <span>Grønt abonnement</span>
            <span>
              {inDKK(addVat(deal?.subscription_green_supplement))} /{" "}
              {periodText(deal.subscription_green_supplement_period)}
            </span>
          </li>
        )}
        {deal.extra_charges &&
          deal.extra_charges.length > 0 &&
          deal.extra_charges.map((charge) => {
            const moreDecimals = charge.price < 0.01;

            return (
              <li key={charge.id} className={"flex justify-between"}>
                <span>{charge.name}</span>
                <span>
                  {inDKK(addVat(charge.price), moreDecimals ? 4 : 2)} /{" "}
                  {extraChargePeriodText(charge.period)}
                </span>
              </li>
            );
          })}
        <li
          className={
            "flex justify-between border-t border-gray-200 pt-2 mt-2 font-medium"
          }
        >
          <span>Årlig udgift</span>
          <span>
            {inDKK(
              addVat(
                pricePrKwh(deal) + subscriptions(deal) + extraCharges(deal),
              ),
            )}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default DealDetail;
