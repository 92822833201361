interface Props {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
}

const RangeSlider = ({
  value,
  onChange,
  min = 0,
  max = 20000,
  step = 500,
}: Props) => {
  return (
    <>
      <input
        id="default-range"
        type="range"
        value={value}
        step={step}
        min={min}
        max={max}
        onChange={(e) => onChange(Number(e.target.value))}
        className="w-full h-2 bg-white rounded-lg appearance-none cursor-pointer accent-[#00CABF] [&::-webkit-slider-thumb]:w-6
  [&::-webkit-slider-thumb]:h-6
  [&::-webkit-slider-thumb]:-mt-2
  [&::-webkit-slider-thumb]:appearance-none
  [&::-webkit-slider-thumb]:bg-[#00CABF]

  [&::-webkit-slider-thumb]:rounded-full
  [&::-webkit-slider-thumb]:transition-all
  [&::-webkit-slider-thumb]:duration-150
  [&::-webkit-slider-thumb]:ease-in-out

  [&::-moz-range-thumb]:w-4
  [&::-moz-range-thumb]:h-4
  [&::-moz-range-thumb]:appearance-none
  [&::-moz-range-thumb]:bg-white
  [&::-moz-range-thumb]:border-4
  [&::-moz-range-thumb]:border-[#00CABF]
  [&::-moz-range-thumb]:rounded-full
  [&::-moz-range-thumb]:transition-all
  [&::-moz-range-thumb]:duration-150
  [&::-moz-range-thumb]:ease-in-out

  [&::-webkit-slider-runnable-track]:w-full
  [&::-webkit-slider-runnable-track]:h-2
  [&::-webkit-slider-runnable-track]:bg-white
  [&::-webkit-slider-runnable-track]:rounded-full
"
      />
    </>
  );
};

export default RangeSlider;
