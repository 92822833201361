import { SubscriptionPeriod } from "../types";

const useSubscriptionFormat = () => {
  const periodText = (format: SubscriptionPeriod) => {
    switch (format) {
      case "P3M":
        return "kvartal";
      case "P1D":
        return "dag";
      case "P1Y":
        return "år";
      case "P1M":
        return "md";
      default:
        return "";
    }
  };

  const extraChargePeriodText = (format: "pr_kwh" | "pr_month" | "pr_year") => {
    switch (format) {
      case "pr_kwh":
        return "kWh";
      case "pr_month":
        return "måned";
      case "pr_year":
        return "år";
      default:
        return "";
    }
  };

  return { periodText, extraChargePeriodText };
};

export default useSubscriptionFormat;
