import { createPortal } from "react-dom";
import { X } from "@phosphor-icons/react";
import { useEffect } from "react";

interface Props {
  children: React.ReactNode;
  active: boolean;
  onClose: () => void;
}

const Modal = ({ children, active, onClose }: Props) => {
  // key esc to close modal
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  // add event listener
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return createPortal(
    <div className={"price-compare-app"}>
      {active && (
        <div
          onClick={onClose}
          className={
            "bg-black/25 fixed inset-0 flex items-center justify-center"
          }
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={
              "max-w-3xl w-full mx-6 bg-white rounded-2xl p-6 md:p-8 lg:p-10 relative"
            }
          >
            <div className={"absolute top-6 right-6 md:top-8 md:right-8"}>
              <button onClick={onClose}>
                <X className={"w-6 h-6"} />
              </button>
            </div>
            {children}
          </div>
        </div>
      )}
    </div>,
    document.body,
  );
};

export default Modal;
