import axios, { AxiosResponse } from "axios";
import { Supplier, SupplierDeal } from "../types";

export interface ApiResponse<T> {
  data: T[];
}

const apiClient = () => {
  const client = axios.create({
    baseURL: "https://api.elberegner.dk",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const getSuppliers = () => {
    return client.get<AxiosResponse<ApiResponse<Supplier>>>("/api/providers", {
      params: {
        with_deals: false,
        only_active: 0,
      },
    });
  };

  const getSupplierDeals = (supplierId: number, kwh = 2000) => {
    return client.get<AxiosResponse<ApiResponse<SupplierDeal>>>(
      `/api/providers/${supplierId}/deals`,
      {
        params: {
          kwh: kwh,
        },
      },
    );
  };

  const getDeal = (dealId: number) => {
    return client.get(`/api/deals/${dealId}`);
  };

  return {
    getSuppliers,
    getSupplierDeals,
    getDeal,
  };
};

export default apiClient;
