import { useAppConfig } from "./useAppConfig";
import { useCallback, useMemo } from "react";
import { SubscriptionPeriod, SupplierDeal } from "../types";

const useCalculation = () => {
  const { currentDeal, kwh, compareDeal, cheapestDeal } = useAppConfig();

  const addVAT = (price: number) => {
    return price * 1.25;
  };

  const subscriptionSupplier = useCallback(
    (period: SubscriptionPeriod) => {
      if (!currentDeal) return 0;
      switch (period) {
        case "P1M":
          return 12;
        case "P3M":
          return 4;
        case "P1Y":
          return 1;
        case "P1D":
          return 365;
        case "P0D":
          return 0;
      }
    },
    [currentDeal],
  );

  const extraChargeMultiplier = useCallback(
    (period: "pr_kwh" | "pr_month" | "pr_year") => {
      switch (period) {
        case "pr_kwh":
          return kwh;
        case "pr_month":
          return 12;
        case "pr_year":
          return 1;
      }
    },
    [kwh],
  );

  const pricePrKwh = useCallback(
    (deal: SupplierDeal) => {
      if (!deal) return 0;
      return (deal.supplement_pr_kwh + deal.green_supplement) * kwh;
    },
    [kwh],
  );

  const subscriptions = useCallback(
    (deal: SupplierDeal) => {
      if (!deal) return 0;

      return (
        deal.subscription_price *
          subscriptionSupplier(deal.subscription_period) +
        deal.subscription_green_supplement *
          subscriptionSupplier(deal.subscription_green_supplement_period)
      );
    },
    [subscriptionSupplier],
  );

  const extraCharges = useCallback(
    (deal: SupplierDeal) => {
      if (!deal || !deal.extra_charges || deal.extra_charges.length === 0)
        return 0;

      return deal.extra_charges.reduce((acc, charge) => {
        return acc + charge.price * extraChargeMultiplier(charge.period);
      }, 0);
    },
    [subscriptionSupplier, kwh],
  );

  const totalCurrent = useMemo(() => {
    if (!currentDeal) return 0;
    return (
      pricePrKwh(currentDeal) +
      subscriptions(currentDeal) +
      extraCharges(currentDeal)
    );
  }, [currentDeal, kwh]);

  const totalCheapest = useMemo(() => {
    if (!cheapestDeal) return 0;
    return (
      pricePrKwh(cheapestDeal) +
      subscriptions(cheapestDeal) +
      extraCharges(cheapestDeal)
    );
  }, [cheapestDeal, kwh]);

  const totalCompare = useMemo(() => {
    if (!compareDeal) return 0;

    return (
      pricePrKwh(compareDeal) +
      subscriptions(compareDeal) +
      extraCharges(compareDeal)
    );
  }, [compareDeal, kwh]);

  const difference = useMemo(() => {
    if (!compareDeal) return 0;

    return addVAT(totalCurrent - totalCompare).toFixed(0);
  }, [compareDeal, totalCheapest, currentDeal, totalCompare, totalCurrent]);

  return {
    hasDeals: !!currentDeal && !!compareDeal,
    totalCurrent,
    pricePrKwh,
    subscriptions,
    difference,
    extraCharges,
    isNegative: totalCurrent < totalCompare,
    addVAT,
  };
};

export default useCalculation;
