const useMoney = () => {
  const inDKK = (amount: number, digits = 2) => {
    return amount.toLocaleString("da-DK", {
      style: "currency",
      currency: "DKK",
      minimumFractionDigits: digits,
    });
  };

  const inDKKOre = (amount: number) => {
    return amount.toLocaleString("da-DK", {
      style: "currency",
      currency: "DKK",
      minimumFractionDigits: 2,
    });
  };

  const addVat = (amount: number) => {
    return amount * 1.25;
  };

  return {
    inDKK,
    inDKKOre,
    addVat,
  };
};

export default useMoney;
