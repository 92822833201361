import { useContext, useState, createContext, useEffect } from "react";
import { SupplierDeal } from "../types";
import useSWR from "swr";
import apiClient from "../lib/apiClient";

interface AppConfig {
  title: string;
  setTitle: (title: string) => void;
  link: string | null;
  setLink: (link: string) => void;
  bgColor: string;
  setBgColor: (bgColor: string) => void;
  color: string;
  setColor: (color: string) => void;
  kwh: number;
  setKwh: (kwh: number) => void;
  compareDeal: SupplierDeal | null;
  currentSupplier: number | null;
  setCurrentSupplier?: (value: number) => void;
  currentDeal: SupplierDeal | null;
  setCurrentDeal?: (deal: SupplierDeal) => void;
  cheapestDeal?: SupplierDeal | null;
  setCheapestDeal?: (deal: SupplierDeal) => void;
}

const AppConfigContext = createContext<AppConfig>({
  title: "",
  setTitle: () => {},
  link: "",
  setLink: () => {},
  bgColor: "",
  setBgColor: () => {},
  compareDeal: null,
  color: "",
  setColor: () => {},
  kwh: 2000,
  setKwh: () => {},
  currentSupplier: null,
  setCurrentSupplier: () => {},
  currentDeal: null,
  setCurrentDeal: () => {},
  cheapestDeal: null,
  setCheapestDeal: () => {},
});

interface initialPropsType {
  title?: string;
  bgColor?: string;
  color?: string;
  dealId?: number;
  link?: string;
  kwh?: number;
}

const AppConfigProvider = ({
  children,
  initialProps,
}: {
  children: React.ReactNode;
  initialProps?: initialPropsType;
}) => {
  const [title, setTitle] = useState<string>(
    initialProps?.title || "[INDSÆT TITLE]",
  );
  const [bgColor, setBgColor] = useState<string>(
    initialProps?.bgColor || "#f5f5f5",
  );
  const [color, setColor] = useState<string>(initialProps?.color || "#0000ff");
  const [currentSupplier, setCurrentSupplier] = useState<number | null>(null);
  const [currentDeal, setCurrentDeal] = useState<SupplierDeal | null>(null);
  const [cheapestDeal, setCheapestDeal] = useState<SupplierDeal | null>(null);
  const [kwh, setKwh] = useState<number>(initialProps?.kwh || 2000);
  const [compareDeal, setCompareDeal] = useState<SupplierDeal | null>(null);
  const [link, setLink] = useState<string | null>(initialProps?.link || null);

  const { data } = useSWR(
    "/deal",
    async () => {
      return await apiClient().getDeal(initialProps?.dealId || 0);
    },
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!data) return;
    setCompareDeal(data.data.data);
  }, [data]);

  useEffect(() => {
    setCurrentDeal(null);
  }, [currentSupplier]);

  return (
    <AppConfigContext.Provider
      value={{
        title,
        setTitle,
        link,
        setLink,
        bgColor,
        setBgColor,
        color,
        compareDeal,
        setColor,
        kwh,
        setKwh,
        currentSupplier,
        setCurrentSupplier,
        currentDeal,
        setCurrentDeal,
        cheapestDeal,
        setCheapestDeal,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfig = () => {
  return useContext(AppConfigContext);
};

export default AppConfigProvider;
