import Button from "./Button";
import { useAppConfig } from "../hooks/useAppConfig";
import Configuration from "./Configuration";
import Totals from "./Totals";

const App = () => {
  const { title, bgColor, color, link } = useAppConfig();
  return (
    <div
      className="w-full rounded-2xl overflow-hidden text-gray-700"
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div className={"grid grid-cols-1 md:grid-cols-5"}>
        <div
          className={
            "col-span-3 p-6 md:p-8 lg:p-10 flex flex-col justify-between space-y-6"
          }
        >
          <h2
            className="text-2xl md:text-3xl font-semibold"
            id={"compare-app-title"}
          >
            Se hvor mange penge du kan spare ved at skifte til{" "}
            <span
              style={{
                color: color,
              }}
            >
              {title}
            </span>
          </h2>
          <Configuration />
          {link && (
            <div className={"hidden md:block mt-6"}>
              <Button color={color} href={link} text={"Tilmeld nu"} />
            </div>
          )}
        </div>
        <div
          className={
            "col-span-2 bg-white p-6 md:p-8 lg:p-10 min-h-64 flex flex-col items-center justify-between text-center space-y-6"
          }
        >
          <Totals />
        </div>
      </div>
    </div>
  );
};

export default App;
