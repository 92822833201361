import { useEffect, useState } from "react";
import { Supplier, SupplierDeal } from "../types";
import useSWR from "swr";
import apiClient from "../lib/apiClient";
import { useAppConfig } from "./useAppConfig";
import useCalculation from "./useCalculation";

const useConfiguration = () => {
  const {
    currentDeal,
    setCurrentDeal,
    currentSupplier,
    setCurrentSupplier,
    setKwh,
    setCheapestDeal,
    kwh,
  } = useAppConfig();

  const { pricePrKwh, subscriptions } = useCalculation();

  const [suppliers, setSuppliers] = useState<Supplier[] | []>([]);
  const [deals, setDeals] = useState<SupplierDeal[] | []>([]);

  const { data: supplierData, isLoading: suppliersLoading } = useSWR(
    ["/suppliers"],
    async () => {
      return await apiClient().getSuppliers();
    },
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!supplierData) return;
    //@ts-ignore
    setSuppliers(supplierData?.data?.data || []);
  }, [supplierData]);

  const { data: dealData, isLoading: dealsLoading } = useSWR(
    ["/deals", currentSupplier],
    async () => {
      if (!currentSupplier) return;
      return await apiClient().getSupplierDeals(currentSupplier);
    },
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (!dealData || !dealData?.data?.data) return;
    //@ts-ignore
    setDeals(
      // @ts-ignore
      dealData?.data?.data?.filter((deal: SupplierDeal) => {
        return deal.price_type === "variable" && !deal?.intro_offer;
      }) || [],
    );
  }, [dealData]);

  useEffect(() => {
    if (deals.length === 0) return;

    if (deals.length === 1) {
      setCurrentDeal && setCurrentDeal(deals[0]);
    }

    if (deals.length > 0) {
      // @ts-ignore
      const cheapestDeal = deals.reduce(
        (prev: SupplierDeal, current: SupplierDeal) => {
          return pricePrKwh(prev) + subscriptions(prev) <
            pricePrKwh(current) + subscriptions(current)
            ? prev
            : current;
        },
      );

      setCurrentDeal && setCurrentDeal(cheapestDeal);

      //setCheapestDeal && setCheapestDeal(cheapestDeal);
    }
  }, [deals, kwh]);

  return {
    suppliers,
    deals,
    suppliersLoading,
    dealsLoading,
    setCurrentSupplier,
    currentSupplier,
    currentDeal,
    setCurrentDeal,
    kwh,
    setKwh,
    formattedKwh: kwh.toLocaleString("da-DK"),
  };
};

export default useConfiguration;
