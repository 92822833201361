import ComboBoxInput from "./ComboBoxInput";
import useConfiguration from "../hooks/useConfiguration";
import { SupplierDeal } from "../types";
import RangeSlider from "./RangeSlider";

const Configuration = () => {
  const {
    suppliers,
    deals,
    currentSupplier,
    setCurrentSupplier,
    currentDeal,
    setCurrentDeal,
    kwh,
    setKwh,
    suppliersLoading,
    dealsLoading,
    formattedKwh,
  } = useConfiguration();

  return (
    <div className={"space-y-2"}>
      <div className={"mb-6"}>
        <RangeSlider
          min={1000}
          max={25000}
          value={kwh}
          onChange={(value) => setKwh(value)}
        />
      </div>
      <div
        className={
          "sm:flex items-center justify-between gap-x-4 text-sm sm:text-base font-medium"
        }
      >
        <div className={"font-light"}>Årligt kWh forbrug</div>
        <div className={" text-base"}>{formattedKwh} kWh</div>
      </div>
      <div
        className={
          "sm:flex items-center justify-between gap-x-4 text-sm sm:text-base font-medium"
        }
      >
        <div className={"font-light"}>Nuv. elselskab</div>
        <div className={""}>
          <ComboBoxInput
            loading={suppliersLoading}
            placeholder={"Vælg elselskab"}
            onChange={(value) => {
              value &&
                setCurrentSupplier &&
                setCurrentSupplier(value as number);
            }}
            value={currentSupplier}
            data={suppliers.map((supplier) => {
              return {
                label: supplier.name,
                value: supplier.id,
              };
            })}
          />
        </div>
      </div>
      <div
        className={
          "sm:flex items-center justify-between gap-x-4 text-sm sm:text-base font-medium"
        }
      >
        <div className={"font-light"}>Nuv. elaftale</div>
        <div className={""}>
          <ComboBoxInput
            loading={dealsLoading}
            disabled={deals.length < 2}
            placeholder={"Vælg elaftale"}
            onChange={(value) => {
              setCurrentDeal &&
                setCurrentDeal(
                  deals.find((deal) => deal.id === value) as SupplierDeal,
                );
            }}
            value={currentDeal ? currentDeal.id : null}
            data={deals.map((deal) => {
              return {
                label: deal.name,
                value: deal.id,
              };
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default Configuration;
