import AppConfigProvider from "./hooks/useAppConfig";
import App from "./components/App";

interface Props {
  title?: string;
  bgColor?: string;
  color?: string;
  dealId?: number;
  link?: string;
  kwh?: number;
}

const Widget = ({ title, bgColor, color, dealId, link, kwh }: Props) => {
  return (
    <AppConfigProvider
      initialProps={{
        title,
        bgColor,
        color,
        dealId: dealId,
        link,
        kwh,
      }}
    >
      <App />
    </AppConfigProvider>
  );
};

export default Widget;
